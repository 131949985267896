import { Box, Container, type ContainerProps } from "@mui/material";
import { type ReactElement, type ReactNode } from "react";

import { PullToRefresh } from "../../PullToRefresh/PullToRefresh";

interface PageWithHeaderProps {
  children: ReactNode;
  appBarHeader: ReactNode;
  onRefresh?: () => Promise<any>;
  footer?: ReactNode;
  /**
   * Default: `with-margin`. This adds a margin around the container
   */
  containerVariant?: "with-margin" | "without-margin";
  /**
   * Default: `xl`.
   * We may deprecate this since the app is mobile. We could lock down to `md`
   */
  containerMaxWidth?: ContainerProps["maxWidth"];
  /**
   * This is the id of the container that will be used to save and restore the scroll position.
   */
  containerId?: string;
}

/**
 * This component must be used inside an `IonPage` since it adds `env(safe-area-inset-top)` and
 * `env(safe-area-inset-bottom)`
 *
 * FIXME, deprecate all usage of `IonPage` and add those margins to this component.
 */
export function PageWithHeader(props: PageWithHeaderProps): ReactElement {
  const {
    children,
    appBarHeader,
    onRefresh,
    containerVariant = "with-margin",
    containerMaxWidth,
    footer,
    containerId,
  } = props;

  const containerHasMargin = containerVariant === "with-margin";
  const containerProps = {
    maxWidth: containerMaxWidth ?? "xl",
    disableGutters: !containerHasMargin,
  };
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      {appBarHeader}
      {onRefresh ? <PullToRefresh onRefresh={onRefresh} /> : null}
      <Box
        {...(containerId ? { id: containerId } : {})}
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          flex: "1",
        }}
      >
        <Container
          {...containerProps}
          sx={{
            flex: 1,
            marginY: containerHasMargin ? 2 : 0,
          }}
        >
          {children}
        </Container>
      </Box>
      {footer ? (
        <Container
          {...containerProps}
          sx={{
            marginTop: "auto",
            marginBottom: containerHasMargin ? 2 : 0,
          }}
        >
          {footer}
        </Container>
      ) : null}
    </Box>
  );
}
