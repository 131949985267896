import { Text } from "@clipboard-health/ui-react";
import { CircularProgress, Dialog, DialogContent, Stack } from "@mui/material";
import { type ReactNode } from "react";

interface ProgressDialogProps {
  modalIsOpen: boolean;
  /**
   * Message content is placed in a typography element, and therefore
   * must be valid for inline content
   */
  messageContent: ReactNode;
  /**
   * Must be between 0 and 100.
   * Progress value used to show a progress bar. If not provided, the
   * progress bar will be indefinite.
   */
  progressValue?: number | undefined;
  /**
   * If true, a label with the progress value will be shown underneath.
   * If false or not provided, the label will not be shown.
   */
  showProgressLabel?: boolean | undefined;
}

export function ProgressDialog(props: ProgressDialogProps) {
  const { modalIsOpen, messageContent, progressValue, showProgressLabel } = props;

  return (
    <Dialog open={modalIsOpen}>
      <DialogContent sx={{ textAlign: "center", marginX: 4, marginY: 3 }}>
        <Text variant="h1">{messageContent}</Text>
        <Stack mt={4} spacing={2} alignItems="center">
          <CircularProgress
            variant={progressValue ? "determinate" : "indeterminate"}
            value={progressValue}
          />
          {showProgressLabel && (
            <Text variant="body2">{progressValue ? `${progressValue}%` : "Please wait..."}</Text>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
